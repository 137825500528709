<template>
  <v-container fluid>

      <v-toolbar rounded>
        <span class="text-h5">Configuration d'authentification</span>

        <v-spacer></v-spacer>

        <authentication-form
            v-model="creatingConfiguration"
            :clientList="configurationList"
            :alreadyExists="alreadyExists"
            actionLabel="Créer"
            title="Création d'une configuration d'authentification"
            @save="saveConfiguration"
        >
          <template v-slot:activator="{attrs, on}">
            <v-btn v-on="on" v-bind:attrs="attrs" color="primary" small @click="reset">Créer</v-btn>
          </template>
        </authentication-form>
      </v-toolbar>


    <v-card class="mt-4">
      <template v-if="loadingList">
        <div class="d-flex flex-column align-center justify-center pa-8">
          <v-progress-circular size="32" indeterminate color="primary"></v-progress-circular>
        </div>
      </template>
      <template v-else>
        <v-list>
          <template v-for="(authConfiguration, i) in configurationList">
            <v-list-item :key="'authentication-configuration-' + i">

              <template #default>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ authConfiguration.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Protocol : {{ authConfiguration.authProtocol.name }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <div class="d-flex flex-row align-center">
                  <v-switch v-model="authConfiguration.active" class="mr-12" label="Actif" @change="confirmActive = i"></v-switch>

                  <authentication-form
                      v-model="creatingConfiguration"
                      :clientList="clientList"
                      :alreadyExists="alreadyExists"
                      actionLabel="Editer"
                      actionColor="warning"
                      title="Edition d'une configuration d'authentification"
                      @save="saveConfiguration(creatingConfiguration, i)"
                  >
                    <template v-slot:activator="{attrs, on}">
                      <v-btn v-on="on" :attrs="attrs" icon @click="editConfiguration(i)">
                        <v-icon>mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </template>
                  </authentication-form>

                  <v-btn icon>
                    <v-icon @click="confirmDelete = i">mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>

            </v-list-item>

            <v-divider :key="'authentication-configuration-separator-' + i"></v-divider>
          </template>

        </v-list>
      </template>
    </v-card>

    <v-dialog :value="confirmDelete !== null" width="25%">
      <v-card>
        <v-toolbar color="danger" dense>
          <v-toolbar-title>Confirmation de suppression</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4">
          <span>Êtes-vous sûr(e) de vouloir supprimer cette configuration ?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDelete = null">Annuler</v-btn>
          <v-btn color="error" :loading="loadingDelete" @click="deleteMessage">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="confirmActive !== null" :value="confirmActive !== null" width="25%" @click:outside="cancelActivation">
      <v-card>
        <v-toolbar color="danger" dense>
          <v-toolbar-title>Confirmation d'activation</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4">
          <span>Êtes-vous sûr(e) de vouloir {{ configurationList[confirmActive].active ? "" : "dés"}}activer cette configuration ?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelActivation">Annuler</v-btn>
          <v-btn color="warning" :loading="loadingDelete" @click="toggleActive">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import {SVSAuthentication, SVSHomepage} from "@/Services/SupportVisionService";
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";

export default {
  name: "LoginSSO",
  components: {
    AuthenticationForm: () => import("@/Components/Views/SupportVision/AuthenticationConfiguration/Form")
  },
  data() {
    return {
      configurationList: [],
      clientList: [],
      creatingConfiguration: null,
      loadingList: false,
      editing: null,
      confirmDelete: null,
      confirmActive: null,
      loadingDelete: false,
      alreadyExists: []
    }
  },
  methods: {
    async saveConfiguration(configuration, index) {
      let response
      if (this.editing === null) {
        response = await SVSAuthentication.postAuthenticationConfiguration(configuration)
        this.configurationList.push(response)
      } else {
        response = await SVSAuthentication.putAuthenticationConfiguration(configuration)
        this.reset()
        this.$set(this.configurationList, index, response.data)
      }
      if (response) {
        this.$nSuccess("Configuration d'authentification " + (this.editing === null ? "créée" : "modifiée"))
      } else {
        this.$nError("Impossible de " + (this.editing === null ? "créer" : "modifier") + " la configuration d'authentification")
      }
    },
    reset() {
      this.creatingConfiguration = {
        title: "",
        active: false,
        client: "",
        idpLinkData: "",
        spLinkData: "",
        loginButtonVisible: false,
        authProtocol: {
          name: "Aucun",
          disabled: false
        },
        authDatas: {}
      }
    },
    async editConfiguration(index) {
      this.editing = index
      this.creatingConfiguration = JSON.parse(JSON.stringify(this.configurationList[index]))
    },
    async deleteMessage() {
      let index = this.confirmDelete
      let _id = this.configurationList[index]._id
      this.loadingDelete = true
      let deleted = await SVSAuthentication.removeAuthenticationConfiguration(_id)
      if (deleted) {
        let index = this.configurationList.findIndex(a => a._id == _id)
        this.configurationList.splice(index, 1)
        this.$nSuccess("Configuration supprimée")
      } else {
        this.$nError("Configuration non supprimée")
      }
      this.confirmDelete = null
      this.loadingDelete = false

    },
    async toggleActive() {
      let index = this.confirmActive
      let toEdit = this.configurationList[index]
      let response = await SVSAuthentication.putAuthenticationConfiguration(toEdit)
      this.confirmActive = null
    },
    cancelActivation() {
      this.configurationList[this.confirmActive].active = !this.configurationList[this.confirmActive].active
      this.confirmActive = null
    },
    getEntityId(authConfiguration) {
      return "http://" + (authConfiguration.client ? authConfiguration.client : "[URL]") + "/planete-online-server/authentication/saml2/metadata.xml"
    },
    async copyEntityId(authConfiguration) {
      await navigator.clipboard.writeText(this.getEntityId(authConfiguration));
      this.$nSuccess("Copié !")
    }
  },
  async mounted() {
    this.loadingList = true
    this.clientList = await PlaneteOnlineService.getBases()
    this.configurationList = await SVSAuthentication.getAuthenticationConfiguration()
    for (let configuration of this.configurationList) {
      this.alreadyExists.push(configuration.client)
    }
    this.loadingList = false
  }
}
</script>

<style scoped>

</style>